<template>
  <transition-group tag="ul" name="list" class="class-ul">
    <li class="class-li" v-for="item in course.data" :key="item.id">
      <router-link
        :to="{
          name: item.courseType > 0 ? 'videodetail' : 'videoRealtime',
          query: { id: item.id },
        }"
      >
        <div class="class-picture-wapper" @click="viewCourse(item.courseType, item.id)">
          <img class="class-bgc" :src="item.coursePicUrl" alt />
          <!-- <img class="class-play-btn" src="http://www.bncc.org.cn/static/m/images/icon26.png" alt /> -->
          <img class="class-play-btn" src="@/assets/img/common/icon26.png" alt />
          <div v-if="item.courseCategory == 231">
            <div class="live_tag green">访谈</div>
          </div>
          <div v-else>
            <div class="live_tag red" v-if="item.courseType == 0">Live</div>
            <div v-else class="live_tag green">课堂</div>
          </div>
        </div>
      </router-link>

      <div class="class-info-wapper">
        <router-link
          :to="{
            name: item.courseType > 0 ? 'videodetail' : 'videoRealtime',
            query: { id: item.id },
          }"
        >
          <div @click="viewCourse(item.courseType, item.id)" class="class-title">
            <span v-if="item.isVerify === 0" style="color: #df0024">【待审核】</span>
            {{ item.courseName }}
          </div>
        </router-link>
        <!-- <div class="class-author" v-if="item.courseCategory && item.courseCategory == 228">
          主讲人：
          <template v-if="item.teacherNames && item.teacherNames.length">
            <router-link
              v-for="(ite, idx) in item.teacherIds"
              :key="ite"
              :to="{ name: 'Personal', query: { uid: ite } }"
              @click="backTop"
            >
              {{ item.teacherNames[idx] }} &nbsp;
            </router-link>
          </template>
          <router-link
            v-else
            :to="{ name: 'Personal', query: { uid: item.createUser } }"
            @click="backTop"
          >
            {{ item.realName }}
          </router-link>
        </div>
        <div class="class-author" v-else>
          主讲人：
          <router-link
            :to="{ name: 'Personal', query: { uid: item.createUser } }"
            @click="backTop"
          >
            {{ item.realName }}
          </router-link>
        </div>-->
        <div class="class-author" v-if="course.courseType == 1">
          主讲人：
          <router-link
            :to="{ name: 'Personal', query: { uid: item.createUser } }"
            @click="backTop"
          >{{ item.realName }}</router-link>
        </div>
        <div class="class-author" v-else>
          主讲人：
          <template v-if="item.chapterList && item.chapterList.length">
            <router-link
              v-for="(ite, idx) in filterName(item.chapterList)"
              :key="idx"
              :to="{ name: 'Personal', query: { uid: ite.teacherId } }"
              @click="backTop"
            >{{ ite.userName }} &nbsp;</router-link>
          </template>
          <router-link
            v-else
            :to="{ name: 'Personal', query: { uid: item.createUser } }"
            @click="backTop"
          >{{ item.realName }}</router-link>
        </div>
        <div class="class-time" v-if="item.timeStr">
          {{ dateFormat(item.liveStartTime) }}
          <span
            v-html="item.courseType == 0 ? item.timeStr : ''"
          ></span>
        </div>
        <div class="class-time" v-else-if="item.liveStartTime">{{ dateFormat(item.liveStartTime) }}</div>
        <div class="class-time" v-else>{{ dateFormat(item.createTime) }}</div>
      </div>
    </li>
  </transition-group>
  <transition-group tag="ul" v-if="course.loading" name="list">
    <li class="class-li skeleton" v-for="item in 10" :key="item">
      <div class="class-picture-wapper skeleton-bg"></div>

      <div class="class-info-wapper">
        <div class="class-title skeleton-bg"></div>

        <div class="class-author skeleton-bg"></div>

        <div class="class-time skeleton-bg"></div>
      </div>
    </li>
  </transition-group>
</template>



<script lang="ts">
import { defineComponent } from "vue";
import dateFormat from "../../utils/dateFormat";
import axios from "axios";
export default defineComponent({
  name: "ClassItem",
  props: {
    course: Object
  },
  setup(props) {
    function viewCourse() {
      console.log(111);
    }
    function backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    function filterName(list: Array<any>) {
      const set = new Set();
      return list.filter(item => {
        if (set.has(item.teacherId)) {
          return false;
        } else {
          set.add(item.teacherId);
          return true;
        }
      });
    }
    return {
      viewCourse,
      dateFormat,
      backTop,
      filterName
    };
  }
});
</script>


<style lang="scss" scoped>
.class-ul {
  padding: 5px 10px 0 10px;
}

.class-li {
  display: block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px 0;
  padding-left: 10px;
  position: relative;
  border-bottom: 1px solid #eee;
  a {
    overflow: hidden;
  }
}

.class-picture-wapper {
  width: 110px;
  height: 90px;
  float: left;
  position: relative;
}

.class-bgc {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.class-play-btn {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11.5px;
  margin-left: -11.5px;
}

.live-tag {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 33.5px;
  height: 15px;
}

.class-info-wapper {
  width: auto;
  height: 100%;
  padding-left: 130px;
}

.class-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #444444;
  font-size: 16px;
  /* // font-weight: bold; */
  height: 40px;
  line-height: 20px;
}

.class-author {
  margin-top: 7px;
  color: #999999;
  font-size: 14px;
  a {
    color: rgb(61, 194, 214);
    font-size: 14px;
  }
}

.class-time {
  margin-top: 10px;
  color: #999999;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
}

/* 课堂 :end */

.list-li {
  border-bottom: 1px solid #eeeeee;
  padding: 17.5px 10px 20px 10px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  padding-left: 80px;
  padding-right: 92.5px;
}

.list-avatar {
  position: absolute;
  left: 10px;
  top: 17.5px;
}

.list-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-name {
  color: #444444;
  font-size: 15px;
}

.user-sign {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999999;
  font-size: 14px;
  margin-top: 10px;
}

.right {
  width: 72.5px;
  height: 27.5px;
  position: absolute;
  right: 10px;
  top: 17.5px;
  border-radius: 27.5px;
  border: 1px solid #df0024;
  color: #df0024;
  line-height: 27.5px;
  text-align: center;
  font-size: 12px;
}

.right img {
  vertical-align: middle;
  width: 11px;
  height: 11px;
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.green {
  background-color: #39b54a;
}
.red {
  background-color: #d64b4b;
}

.live_tag {
  height: 20px;
  line-height: 20px;
  padding-left: 20px;
  color: #fff;
  font-size: 12px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding-right: 5px;
}

.live_tag::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 7.5px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.skeleton {
  .class-info-wapper {
    padding-right: 10px;
  }
  .class-title {
    height: 15px;
  }
  .class-author {
    height: 10px;
    margin-top: 32px;
    width: 120px;
    margin-bottom: 20px;
  }
  .class-time {
    height: 10px;
    width: 80px;
  }

  .skeleton-bg {
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 74.5%, 0.2) 25%,
      hsla(0, 0%, 50.6%, 0.24) 37%,
      hsla(0, 0%, 74.5%, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}
</style>
