<template>
  <menu-tab
    :menu="menu"
    :swiper="true"
    :slidesPerView="5"
    :initIndex="index"
    @change="selectMenu"
  ></menu-tab>

  <list
          url="/M/Home/OpenSearchMore"
          :params="{ type: 'bncc', keyword: keyword }"
          :filterFunc="strainOrCellsMap"
          :show="currentMenuItem == '产品'"
  >
    <template v-slot:default="data">
      <bncc-product-card :list="data" name="bnccdetail" tid="3"></bncc-product-card>
    </template>
  </list>
<!--  <list-->
<!--          url="/M/Home/OpenSearchMore"-->
<!--          :params="{ type: 'cells', keyword: keyword }"-->
<!--          :filterFunc="strainOrCellsMap"-->
<!--          :show="currentMenuItem == '细胞'"-->
<!--  >-->
<!--    <template v-slot:default="data">-->
<!--      <bncc-product-card :list="data" name="bnccdetail" tid="3"></bncc-product-card>-->
<!--    </template>-->
<!--  </list>-->
  <list url="/M/Home/OpenSearchMore" :params="{ type: 'news', keyword: keyword }" :show="currentMenuItem == '新闻'">
    <template v-slot:default="data">
      <news-item :newData="data"></news-item>
    </template>
  </list>
  <list url="/M/Home/OpenSearchMore" :params="{ type: 'topic', keyword: keyword }" :show="currentMenuItem == '帖子'">
    <template v-slot:default="data">
      <topic-item :topicData="data"></topic-item>
    </template>
  </list>
  <list url="/M/Home/OpenSearchMore" :params="{ type: 'course', keyword: keyword }" :show="currentMenuItem == '培训'">
    <template v-slot:default="data">
      <class-item :course="data"></class-item>
    </template>
  </list>

</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import MenuTab from "@/components/common/MenuTab.vue";
import list from "@/components/common/list.vue";
import bnccProductCard from "@/components/common/bnccProductCard.vue";
import productCard from "@/components/common/productCard.vue";
import newsItem from "@/components/common/NewsItem.vue";
import TopicItem from "@/components/common/TopicItem.vue";
import ClassItem from "@/components/common/ClassItem.vue";
import Toast from "@/components/UI/Toast/";

//ts
import {strainOrCellsMap } from "@/hooks/useProduct";

export default defineComponent({
  name: "OpenSearch",
  components: {
    MenuTab,
    bnccProductCard,
    list,
    newsItem,
    TopicItem,
    ClassItem,
  },
  setup() {
    //  切换列表菜单
    type Menu = "产品" |"新闻" | "培训" | "帖子"  ;
    const menu: Menu[] = [
      "产品",
      "新闻",
      "培训",
      "帖子",

    ];

    const route = useRoute();
    const type = route.query.type;
    let index = 0;
    if (type) {
      index = menu.findIndex((item) => {
        return item == type;
      });
    }

    const keyword = route.query.keyword;
    const currentMenuItem = ref(menu[index]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    return {
      menu,
      currentMenuItem,
      selectMenu,
      index,
      keyword,
      strainOrCellsMap,
    };
  },
});
</script>

