
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import MenuTab from "@/components/common/MenuTab.vue";
import list from "@/components/common/list.vue";
import bnccProductCard from "@/components/common/bnccProductCard.vue";
import productCard from "@/components/common/productCard.vue";
import newsItem from "@/components/common/NewsItem.vue";
import TopicItem from "@/components/common/TopicItem.vue";
import ClassItem from "@/components/common/ClassItem.vue";
import Toast from "@/components/UI/Toast/";

//ts
import {strainOrCellsMap } from "@/hooks/useProduct";

export default defineComponent({
  name: "OpenSearch",
  components: {
    MenuTab,
    bnccProductCard,
    list,
    newsItem,
    TopicItem,
    ClassItem,
  },
  setup() {
    //  切换列表菜单
    type Menu = "产品" |"新闻" | "培训" | "帖子"  ;
    const menu: Menu[] = [
      "产品",
      "新闻",
      "培训",
      "帖子",

    ];

    const route = useRoute();
    const type = route.query.type;
    let index = 0;
    if (type) {
      index = menu.findIndex((item) => {
        return item == type;
      });
    }

    const keyword = route.query.keyword;
    const currentMenuItem = ref(menu[index]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    return {
      menu,
      currentMenuItem,
      selectMenu,
      index,
      keyword,
      strainOrCellsMap,
    };
  },
});
